<template>

        <nav v-if="this.$route.name === 'WizardPage'" >
            <div class="container">
                <div class="row">
                    <!-- Logo -->
                    <div class="col-9">
                        <a class="logo icon-img-120-x py-2" >
                            <img src="assets/imgs/logo.svg" alt="logo" width="80" />
                            <span class="d-inline-block px-2 text-dark">
                                الـفـاو لتاجير السـيارات
                            </span>
                        </a>
                    </div>
                    <div class="col-3">
                        <div class="mt-3">
                            <a  href="/" type="button" class="btn btn-primary float-start">
                                الرئيسية
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>

        <nav v-else  class="navbar nav-crev navbar-expand-lg change">
            <div class="container">
                <a class="logo icon-img-120-x py-2" href="#">
                    <img src="assets/imgs/logo.svg" alt="logo" width="80" />
                    <span class="d-inline-block px-2">
                        الـفـاو 
                        <small>
                            لتاجير السـيارات
                        </small>
                    </span>
                </a>
        
                <!-- span class="icon-bar text-white"><i class="fas fa-bars"></i></span -->
        
                <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link" href="#0"><span>البداية</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#0"><span>من نحن</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#0"><span>فروعنا</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#0"><span>التواصل</span></a>
                        </li>
                    </ul>
                </div>
        
                <div class="search-form">
                    <a href="/reant" type="button" class="btn btn-primary">
                        حجز سيارة
                    </a>
                </div>
            </div>
        </nav>





</template>

<script>
export default {
  name: 'NavPage',
  props: {

  }
}
</script>