<template>
  <div>
    <!--
      {{ $route.fullPath }}
      <router-link to="/">Go to Home</router-link>
      <router-link to="/reant">Go to Reant</router-link>
    -->
    
    <Nav />
    <RouterView />
    <Footer />
  </div>
</template>

<script>
import Footer from './components/FooterPage.vue';
import Nav from './components/NavPage.vue';

export default {
  name: 'App',
  components: {
    Nav,
    Footer
  }
}
</script>